.viewer-button {
	transform: scale(1.5);
}

.ios .viewer-button.viewer-close {
	top: calc(env(safe-area-inset-top, 0) + 35px);
	right: calc(env(safe-area-inset-right, 0) + 35px);
}

.ios .viewer-button.viewer-close::before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
