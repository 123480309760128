/* stylelint-disable declaration-no-important */
ngx-emoji.ngx-emoji-inline {
	display: flex;
	align-items: center;
	justify-content: center;
}

.emoji-mart-modal .emoji-mart {
	width: 100% !important;
	height: 100% !important; /* or any specific height you want */
	max-height: 100vh !important; /* prevent overflow beyond viewport */
}

.emoji-mart-modal .emoji-mart-scroll {
	height: calc(100% - 95px) !important; /* subtract header height */
}

/* Ensure categories still scroll properly. */
.emoji-mart-modal .emoji-mart-category {
	width: 100%;
}
